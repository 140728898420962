
import React from 'react';

export default function ResendEmailOrder() {

  return (
    <React.Fragment>
            <h2>Reenviar Estatus de una Orden</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/resend/
              </code>
            </pre>
            <p>
              Reenvia un email de notificacion al customer asociado a la orden con el estatus que 
              fue procesada la orden (confirmada/cancelada). Si se trata de una orden confirmada, 
              el email mostrara datos del customer, detalles de la orden, detalles del pago y la razon 
              de confirmacion indicada por el restaurantero. Si se trata de una orden cancelada, el email 
              mostrara datos del customer, informacion sobre el reembolso (si aplica) y la razon de cancelacion 
              indicada por el restaurantero.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguiente parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    id: 45896,
    to: "email@mail.com",
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>Integer</td>
                  <td>Yes.</td>
                  <td>Order number in TastyBoom.
                  <br/>Examples: 45896</td>
                </tr>
                <tr>
                  <td>to</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Correo electronico  receptor.
                  <br/>Examples: email@mail.com</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    success: "Email's have been sent"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>success</td>
                  <td>Confirmation message.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
