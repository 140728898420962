
import React from 'react';

export default function PasswordReset() {

  return (
    <React.Fragment>
            <h2>Password Reset TastyBoom Orders App</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/panel/password/reset/
              </code>
            </pre>
            <p>
              Envia un email al usuario registrado en TastyBoom Orders App con un codigo para recuperar
              el acceso a dicho panel a traves de su funcion de reset-password.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguiente parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    email: "email@mail.com",
    token: "eyJ0eXAiOiJKV1",
    locale: "en",
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>email</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Correo electronico del usuario.
                  <br/>Examples: email@mail.com</td>
                </tr>
                <tr>
                  <td>token</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Codigo que debe usar el usuario para resetear su password.</td>
                </tr>
                <tr>
                  <td>locale</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Siglas del idioma bajo el cual se enviara el contenido del email.
                  <br/>Examples: "en" para ingles.</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    success: "Email Reset Password Sended"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>success</td>
                  <td>Confirmation message.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
