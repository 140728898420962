
import React from 'react';

export default function ContactAtomic() {

  return (
    <React.Fragment>
            <h2>Email de Contacto para Atomic</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/atomic/
              </code>
            </pre>
            <p>
              Envia un email con la informacion de contacto del restaurantero o futuro cliente de Atomic Kitchens.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguiente parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    name: "Maria Contreras",
    email: "email@mail.com",
    subject: "Test subject",
    message: "Test message contact",
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>name</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Nombre completo de la persona que esta realizando el contacto.</td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Correo electronico de la persona que esta realizando el contacto.
                  <br/>Examples: email@mail.com</td>
                </tr>
                <tr>
                  <td>subject</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Asunto ingresado por la persona que esta realizando el contacto.</td>
                </tr>
                <tr>
                  <td>message</td>
                  <td>String</td>
                  <td>Yes.</td>
                  <td>Detalles ingresados por la persona que esta realizando el contacto.</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    success: "The email was send"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>success</td>
                  <td>Confirmation message.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
