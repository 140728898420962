
import React from 'react';

export default function EmailRider() {

  return (
    <React.Fragment>
            <h2>Notificacion de Rider Fallido</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api/rider/
              </code>
            </pre>
            <p>
              Envia un email al restaurantero asociado a TastyBoom notificandole que la solicitud de rider
              ha fallado por alguna razon especifica.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando los siguiente parametros:<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    order: 45896,
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>order</td>
                  <td>Integer</td>
                  <td>Yes.</td>
                  <td>Order number in TastyBoom.
                  <br/>Examples: 45896</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    success: "The email was send"
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>success</td>
                  <td>Confirmation message.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
